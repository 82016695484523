import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import { Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const Others = () => (
  <Layout>
    <Seo title="Others" />
    <Banner title="Others" />
    <section id="services" className="features-area pt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Row id="printers">
              <div className="col-lg-5">
                <StaticImage
                  imgClassName="mr-3 fluid-img"
                  src="../images/printers.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Hero"
                  placeholder="blurred"
                />
              </div>
              <div className="col-lg-5">
                <h3 className="mt-0 mb-1">Printers</h3>
                <h6 className="mt-2 mb-2">Available Brands</h6>
                <p>HP, Samsung</p>
                <h6 className="mt-2 mb-2">Available Configuration</h6>
                <p>Printing: 4 Coloured/ 2 Coloured</p>
                <p>Printing Load: from 50 to 500 pages per day</p>
                <p>Consumables: Toner/ Cartridges</p>
              </div>
            </Row>
            <Row id="cctvs">
              <div className="row mt-5 pt-5 pb-5">
                <div className="col-lg-5">
                  <StaticImage
                    imgClassName="mr-3 fluid-img"
                    src="../images/cctvs.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Hero"
                    placeholder="blurred"
                  />
                </div>
                <div className="col-lg-5">
                  <h3 className="mt-0 mb-1">CCTVs</h3>
                  <h6 className="mt-2 mb-2">Available Brands</h6>
                  <p>CP Plus</p>
                  <h6 className="mt-2 mb-2">Available Configuration</h6>
                  <p>2 Megapixel Network Camera</p>
                  <p>3 Megapixel Network Camera</p>
                  <p>4 Megapixel Network Camera</p>
                  <p>5 Megapixel Network Camera</p>
                  <p>6 Megapixel Network Camera</p>
                  <p>4K 8 Megapixel Network Camera</p>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Others
